<template>
    <div class="apaympro-container">
        
        <!-- Loader -->
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="bars"
            :opacity="1"
        ></loading>

       
        <!-- Form -->
        <div class="apaympro-wrapper animated fadeIn container">
            <div id="payment-page-pay" class="apaympro-page">
                

                <!-- Informations du marchand -->
                <div class="apaympro-header" style="margin-top: 30px;">
                    <div class="company-logo" style="width: 150px !important; justify-content: center;">
                        <img
                            id="company-logo-img"
                            src="../assets/apaym-pay.png"
                            alt="Business Logo"
                        />
                    </div>

                    <div class="page-info mb-4">

                        <h2 id="page-name" class="page-name">
                            Formulaire de test - Orabank APIs
                        </h2>

                    </div>

                </div>
                <!-- Informations du marchand -->

                <!-- Formulaire -->
                <div class="body">

                    <form name="payment-form" class="payment-form">

                        <!-- Montant -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    Montant
                                </span>
                            </div>

                            <div class="element-pair-container" style="align-items: center">
                                
                                <div 
                                    class="element-wrap element-wrap-on-right"
                                >
                                    <div
                                        style="
                                            display: flex; 
                                            flex-direction: row; 
                                            align-items: center;
                                        "
                                    >
                                        <input
                                            :disabled="dataFormulaire.amount !== '' && parseInt(dataFormulaire.amount) >= 100 "
                                            v-model="dataFormulaire.montant"
                                            type="tel"
                                            :tabindex="1"
                                            v-on:keypress="numbersOnly"
                                            class="element-is-input"
                                            name="montant"
                                            maxlength="7"
                                            v-b-tooltip.hover :title="dataFormulaire.montant !== '' && dataFormulaire.montant < 100 ? 'Vous pouvez payer au minimum 100 F ' : ''"
                                            :style="dataFormulaire.montant !== '' && dataFormulaire.montant < 100  ? 'border: 1px solid red; font-size: 22px; font-weight: 900;' : 'font-weight: 900; font-size: 22px'"
                                        />
                                    </div>
                                </div>
                                <div
                                    style="font-size: 20px; font-weight: 800; white-space: nowrap;"
                                >
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant -->

                        <!-- Nom du propriétaire de la carte -->
                        <div class="element-pair mt-3">
                            <div class="element-label">
                                <span>
                                    Nom du propriétaire de la carte
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                
                                <div class="element-wrap element-wrap-on-right">
                                    <input
                                        v-model="dataFormulaire.cardholdername"
                                        type="text"
                                        :tabindex="2"
                                        class="element-is-input"
                                        name="cardholdername"
                                        style="font-weight: 900; font-size: 16px"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Nom du propriétaire de la carte -->

                        <!-- PAN de la carte -->
                        <div class="element-pair mt-3">
                            <div class="element-label">
                                <span>
                                    Les 16 chiffres de la carte
                                </span>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <i 
                                    class="fa fa-credit-card" 
                                    v-if=" !dataFormulaire.issuer_name && dataFormulaire.invalid_pan !== 1 "
                                    style="margin-left: 10px; position: absolute;"
                                ></i>
                                <input
                                    type="tel"
                                    class="element-is-input"
                                    name="pan"
                                    :tabindex="3"
                                    v-mask="'#### #### #### ####'"
                                    v-model="dataFormulaire.pan"
                                    @keypress="numbersOnly"
                                    v-b-tooltip.hover 
                                    :title="dataFormulaire.pan !== '' && dataFormulaire.pan.replace(/\s/g, '').length < 16  ? 'Renseigner les 16 chiffres de votre carte ' : '' "
                                    :style="dataFormulaire.pan !== '' && dataFormulaire.pan.replace(/\s/g, '').length < 16  ? 'border: 1px solid red; font-weight: 900; font-size: 22px; padding-left: 35px;' : 'font-weight: 900; font-size: 22px; padding-left: 35px;'"
                                />

                                <!-- Loader PAN -->
                                <!-- <i
                                    class="fas fa-spinner fa-spin text-muted"
                                    style="font-size: 20px; margin-left: -28px;"
                                    v-if="showLoaderPan"
                                ></i> -->
                                <!-- Loader PAN -->

                                <!-- Badge success - PAN is a valid PAN -->
                                <!-- <img  
                                    src="../assets/img/badge-check.png" 
                                    alt="badge checked" width="22"
                                    style="margin-left: 10px; position: absolute;"
                                    v-if="dataFormulaire.issuer_name && dataFormulaire.pan && dataFormulaire.pan.replace(/\s/g, '').length == 16"
                                > -->

                                <!-- Badge error - PAN is not a valid PAN -->
                                <!-- <img  
                                    src="../assets/img/badge-error.png" 
                                    alt="badge checked" width="22"
                                    style="margin-left: 10px; position: absolute;"
                                    v-if="dataFormulaire.invalid_pan == 1 && dataFormulaire.pan && dataFormulaire.pan.replace(/\s/g, '').length == 16" 
                                > -->

                            </div>
                        </div>


                        <!-- CVV et Date d'expiration de la carte -->
                        <div class="element-pair mt-3">
                            <!-- <div class="element-label">
                                <span> Date d'expiration et cvv </span> 
                            </div> -->
                            <div class="element-pair-container">
                                <div
                                    class="element-wrap element-wrap-on-left"
                                >
                                    <div class="element-label">
                                        <span> Date d'expiration </span> 
                                    </div>
                                    <input
                                        class="element-is-input"
                                        type="tel"
                                        :tabindex="4"
                                        v-mask="'####-##'"
                                        v-model="dataFormulaire.dateExpiration"
                                        name="dateExpiration"
                                        placeholder="2022-06"
                                        style="font-weight: 900; font-size: 22px"
                                    />
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span> CVV </span> 
                                    </div>
                                    <div style="display: flex; flex-direction: row; align-items: center;">
                                        <input
                                            class="element-is-input"
                                            type="password"
                                            v-mask="'###'"
                                            v-model="dataFormulaire.cvv"
                                            name="cvv"
                                            :tabindex="5"
                                            placeholder="123"
                                            style="font-weight: 900; font-size: 22px;"
                                        />
                                        <i 
                                            class="fa fa-info font-14" 
                                            style="margin-left: -30px; background-color: black; color: rgb(255, 255, 255); border-radius: 50%;
                                                width: 20px;
                                                height: 20px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                cursor: pointer;
                                            "
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CVV et Date d'expiration de la carte -->

                    
                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div class="pb-4">
                                <button
                                    type="button"
                                    @click="valider"
                                    class="btn btn-dark btn-block"
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>
                </div>
                <!-- Formulaire -->
            </div>
        </div>
        <!-- Form -->


        <!-- Footer APaym Secured Badge -->
        <footer-item :hide_wgy_infos="'0'"></footer-item>
        <!-- Footer APaym Secured Badge -->
        
    </div>
</template>


<script>

    import Loading from "vue-loading-overlay" ;
    import "vue-loading-overlay/dist/vue-loading.css" ;
    // import json from "../constants/flags.json" ;
    // import delegations_pdci from "../constants/delegation-pdci.json" ;
    // import motifs_pdci from "../constants/motif-pdci.json" ;
    // import grades_pdci from "../constants/grade-pdci.json" ;
    // import currencies_list from "../constants/currencies.json" ;
    // import { sweetAlert } from "../constants/functions" ;
    import axios from "axios" ;
    // import { apiUrl, _reference } from "../constants/config" ;
    // import { trim } from "jquery" ;
    // import Multiselect from "vue-multiselect" ;
    // import TopBar from "../components/TopBar" ;
    // import SkeletonLoader from "../components/SkeletonLoader" ;
    // import SearchMerchant from "../components/SearchMerchant" ;
    import FooterItem from "../components/FooterItem" ;
    // import SuccessPage from "../components/SuccessPage" ;
    // import VueHcaptcha from "@hcaptcha/vue-hcaptcha" ;

    // const TIME_LIMIT = 180; 

    export default {
        created () {
        },
        props: {
            reCaptchaCompat: {
                type: Boolean,
                default: true
            },
            sentry: {
                type: Boolean,
                default: true
            },
        },
        components: {
            Loading,
            // multiselect: Multiselect,
            // "skeleton-loader" : SkeletonLoader,
            // "search-merchant" : SearchMerchant,
            "footer-item" : FooterItem,
            // "success-page" : SuccessPage
        },
        data() {
            return {
                isLoading: false, fullPage: true, showLoaderPan: false,
                dataFormulaire: {
                    cardholdername: "",
                    cvv: "",
                    dateExpiration: "",
                    issuer_name: "",
                    card_type_code: "",
                    card_type_description: "",
                    pan: "",
                    last_4_digit: "",
                    montant: "",
                    invalid_pan: 0,
                },
                dataLocation: {
                    latitude: 0,
                    longitude: 0
                },
                dataAdresseIp: {
                    adresse_ip : "",
                    organisation: "",
                    ville: "",
                    region: "",
                    pays: ""
                }
            }
        },
        computed: {
        },
        methods: {

            onCancel() {
                console.log('Loader dismiss') ;
            },

            // Allows numbers only, amount field
            numbersOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            
            valider(){

                if (  
                    this.dataFormulaire.pan.replace(/\s/g, '') == ""
                    || this.dataFormulaire.montant == ""
                    || this.dataFormulaire.cardholdername.replace(/\s/g, '') == ""
                    || this.dataFormulaire.dateExpiration == ""
                    || this.dataFormulaire.cvv == ""
                )
                {
                    this.$swal({
                        title: 'Information',
                        icon: 'info',
                        html:"<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner toutes les informations du formulaire !</strong>",
                    });
                    return false ;
                }

                window.location.href = "https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan="+this.dataFormulaire.pan.replace(/\s/g, '')
                    +"&amount="+ this.dataFormulaire.montant +"&cardholderName="+ this.dataFormulaire.cardholdername +
                    "&expiry="+ this.dataFormulaire.dateExpiration + "&cvv="+ this.dataFormulaire.cvv +"&slug=visuelconcepts"
                ;

                /*this.isLoading = true ;

                let dataSend = new FormData();
                dataSend.append("pan", this.dataFormulaire.pan.replace(/\s/g, ''));
                dataSend.append("amount", this.dataFormulaire.montant);
                dataSend.append("cardholderName", this.dataFormulaire.cardholdername);
                dataSend.append("expiry", this.dataFormulaire.dateExpiration);
                dataSend.append("cvv", this.dataFormulaire.cvv);

                axios.post('https://carte.abidjan.net/mobilemoney/paymentrequest.php',
                    dataSend
                ).then(response => {
                    console.table(dataSend);
                    console.log(response);
                    this.isLoading = false ;
                    
                }).catch(error => {

                    this.isLoading = false ;
                    console.table(dataSend);
                    console.log(error);   
                    this.$swal({
                        title: 'Erreur',
                        icon: 'error',
                        html:"<strong class='font-16' style='line-height: 1.2'>Une erreur est survenue, veuillez réessayer.</strong>",
                    });                 
                });*/

            },

            // Vérifier le PAN renseigné
            checkPan(evt)
            {

                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                    return false ;
                }

                this.dataFormulaire.invalid_pan = 0 ;
                this.dataFormulaire.issuer_name = "" ;
                this.dataFormulaire.last_4_digit = "" ;
                this.dataFormulaire.card_type_code = "" ;
                this.dataFormulaire.card_type_description = "" ;

                if ( this.dataFormulaire.pan.replace(/\s/g, '').length < 16 ) {
                    this.dataFormulaire.invalid_pan = 0 ;
                    this.dataFormulaire.issuer_name = "" ;
                    this.dataFormulaire.last_4_digit = "" ;
                    this.dataFormulaire.card_type_code = "" ;
                    this.dataFormulaire.card_type_description = "" ;
                    return false;
                }

                // Literal Object JavaScript
                const cardType = {
                    'C' : "Crédit" ,
                    'D' : "Débit" ,
                    'H' : "Charge Card" ,
                    'P' : "Prépayée" ,
                    'R' : "Débit différé"
                }

                // Init process
                this.showLoaderPan = true ;
                let dataSend = new FormData();
                dataSend.append("pan", this.dataFormulaire.pan.replace(/\s/g, ''));

                axios.post( 'https://carte.abidjan.net/cabine/api/check-pan',
                    dataSend
                ).then(response => {

                    this.showLoaderPan = false ;
                    // On a obtenu un message d'erreur
                    if ( 
                        ( response["data"]["errorMessage"] || response["data"]["ppgsNetworkInfo"] ) && 
                        !response["data"]["visaNetworkInfo"]
                    ) 
                    {
                        this.dataFormulaire.invalid_pan = 1 ;
                        this.$swal({
                            title: 'Information',
                            icon: 'info',
                            html:"<strong class='font-16' style='line-height: 1.5'>Les 16 chiffres renseignés ne sont pas valides ! "+
                                "Assurez-vous de renseigner les 16 chiffres d'une carte Visa valide !</strong>",
                        });
                        return false ;
                    }

                    // La devise du PAN renseigné n'est pas le XOF = 952
                    if ( 
                        response["data"]["visaNetworkInfo"][0]["billingCurrencyCode"] !== 952 
                    )
                    {
                        this.dataFormulaire.invalid_pan = 1 ;
                        this.$swal({
                            title: 'Erreur',
                            icon: 'error',
                            html:"<strong class='font-16' style='line-height: 1.2'>Votre carte n'est pas acceptée pour ce service.</strong>",
                        });
                        return false ;
                    }
                     

                    // Le PAN renseigné respecte toutes les conditions pour effectuer le rechargement
                    this.dataFormulaire.invalid_pan = 0 ;
                    this.dataFormulaire.show_fields = true ;
                    this.dataFormulaire.issuer_name = response["data"]["visaNetworkInfo"][0]["issuerName"] ;
                    this.dataFormulaire.card_type_code = response["data"]["visaNetworkInfo"][0]["cardTypeCode"] ;
                    this.dataFormulaire.card_type_description = cardType[this.dataFormulaire.card_type_code] ;
                    this.dataFormulaire.last_4_digit = this.dataFormulaire.pan.replace(/\s/g, '').slice( this.dataFormulaire.pan.replace(/\s/g, '').length - 4 ) ;

                }).catch(error => {

                    this.showLoaderPan = false ;
                    console.log(error);   
                    this.$swal({
                        title: 'Erreur',
                        icon: 'error',
                        html:"<strong class='font-16' style='line-height: 1.2'>Une erreur est survenue, veuillez réessauer.</strong>",
                    });                 
                });

            },

            getIpAddress(){
                axios.get('https://ipinfo.io/json?token=8c8fd7115a940f').then(response => {
                    this.dataAdresseIp.adresse_ip = response.data.ip ;
                    this.dataAdresseIp.organisation = response.data.org ;
                    this.dataAdresseIp.ville = response.data.city ;
                    this.dataAdresseIp.region = response.data.region ;
                    this.dataAdresseIp.pays = response.data.country ;
                }).catch(err => {
                    console.log(err) ;
                });
            },

            getLocation() {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        console.log("latitude = "+ position.coords.latitude);
                        console.log("longitude = "+ position.coords.longitude);
                        this.dataLocation.latitude = position.coords.latitude ;
                        this.dataLocation.longitude = position.coords.longitude ;
                    },
                    error => {
                        console.log(error.message);
                    },
                )
            },

        },
        mounted() {
            this.getIpAddress() ;
            // this.getLocation() ;
        },
        updated() {
        },
        watch: {
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .btn-close-modal {
        position: absolute;
        font-size: 15px;
        color: #1b1e21;
        top: -4px;
        cursor: pointer;
        font-weight: 700;
        left: 102%;
        height: 25px;
        width: 25px;
    }

    .btn-close-modal-small {
        position: absolute;
        color: #1b1e21;
        top: 0;
        cursor: pointer;
        font-weight: 700;
        right: 0;
        /* height: 25px; */
        width: 25px;
        z-index: 50;
    }

    .text-modal-small {
        position: relative;
        color: #1b1e21;
        top: 5px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        white-space: break-spaces;
        align-items: baseline;
    }

    .btn-disabled-custom {
        background: rgb(0 0 0 / 8%);
        border: rgb(0 0 0 / 8%);
        color: #1b1e21;
    }

    .searchIcon {
        margin-left: -35px;
        cursor: pointer;
        /* color: #261b64; */
        color: #343a40;
        cursor: pointer;
        font-size: 25px;
        z-index: 50;
    }

    .payment__type {
        cursor: pointer;
        transition: all 0.15s ease;
        margin-right: 0px;
        white-space: nowrap;
    }

    .pdf-content {
        width: 100%;
        background: #fff;
    }

    .pdf-content .report-info {
        display: flex;
        padding: 20px;
        padding-bottom: 0px;
    }

    .pdf-content .report-info .user-details {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
    }

    /* h3::first-letter{
        color: #ffdddd !important;
        font-weight: 900;
    } */

    .cols,
    .columns {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }
    .cols.col-gapless,
    .columns.col-gapless {
        margin-left: 0;
        margin-right: 0;
    }
    .cols.col-gapless > .column,
    .columns.col-gapless > .column {
        padding-left: 0;
        padding-right: 0;
    }
    .cols.col-oneline,
    .columns.col-oneline {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .cols.col-gapless > .column,
    .columns.col-gapless > .column {
        padding-left: 0;
        padding-right: 0;
    }
    .cols.col-oneline,
    .columns.col-oneline {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    /* .column,
    [class~="col-"] {
        -webkit-flex: 1 1;
        flex: 1 1;
        max-width: 100%;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    } */
    .column.col-1,
    .column.col-2,
    .column.col-3,
    .column.col-4,
    .column.col-5,
    .column.col-6,
    .column.col-7,
    .column.col-8,
    .column.col-9,
    .column.col-10,
    .column.col-11,
    .column.col-12,
    .column.col-auto,
    [class~="col-"].col-1,
    [class~="col-"].col-2,
    [class~="col-"].col-3,
    [class~="col-"].col-4,
    [class~="col-"].col-5,
    [class~="col-"].col-6,
    [class~="col-"].col-7,
    [class~="col-"].col-8,
    [class~="col-"].col-9,
    [class~="col-"].col-10,
    [class~="col-"].col-11,
    [class~="col-"].col-12,
    [class~="col-"].col-auto {
        -webkit-flex: none;
        flex: none;
    }

    .column {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }

    @media (max-width: 480px) {
        .main-layout .children .product-column {
            padding: 0 10px;
        }
    }
    @media (max-width: 600px) {
        .main-layout .children .product-column {
            padding: 0 10px;
        }
    }

    .product {
        margin-top: 30px;
    }
    .product {
        border-radius: 6px;
        height: 280px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        cursor: pointer;
    }
    @media (max-width: 480px) {
        .product {
            margin-top: 10px;
        }
    }
    @media (max-width: 600px) {
        .product {
            margin-top: 10px;
        }
    }
    
    /* .product:hover .product__details__name {
        text-decoration: underline;
    } */
    .product__media-container {
        border-radius: 6px 6px 0 0;
        background: #eceaeb;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        height: 250px;
        overflow: hidden;
    }
    .product__media-container--single-file {
        display: -webkit-flex;
        display: flex;
        padding: 20px;
    }
    .product__media {
        max-width: 100%;
        max-height: 100%;
        border-radius: 6px;
        object-fit: contain;
        outline: none;
    }
    .product__details {
        background: #fff;
        border-radius: 0 0 6px 6px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 20px 20px;
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }
    .product__details__price {
        color: #1a1a1a;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        text-align: center;
    }
    @media (max-width: 600px) {
        .product__details__price {
            text-align: center;
            font-size: 13px;
            line-height: 16px;
        }
    }
    @media (max-width: 480px) {
        .product__details__price {
            text-align: center;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .product__details__name {
        color: #57584e;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        z-index: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
            text-align: center;
    }
    .product__details__name a {
        color: #57584e;
    }
    @media (max-width: 600px) {
        .product__details__name {
            text-align: center;
            font-size: 15px;
            line-height: 18px;
        }
    }
    @media (max-width: 480px) {
        .product__details__name {
            text-align: center;
            font-size: 15px;
            line-height: 18px;
        }
    }

    .modal-container .product-view__media--img,
    .product-view__media--img {
        pointer-events: none;
    }
    .modal-container .product-view__media--img,
    .modal-container .product-view__media--video,
    .product-view__media--img,
    .product-view__media--video {
        max-width: 100%;
        max-height: 100%;
        border-radius: 6px;
        object-fit: contain;
        outline: none;
    }

    .blocAbsolute {
        display: flex;
        position: absolute;
        flex-direction: row;
        z-index: 1;
        align-items: center;
        width: 100%;
        padding: 3px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(255, 159, 0);
    }

    .blocNonAbsolute {
        display: flex;
        flex-direction: row;
        z-index: 1;
        align-items: center;
        width: 100%;
        padding: 3px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(255, 159, 0);
    }

    .timer {
        position: relative;
        /* top: 6px; */
        font-size: 28px;
        font-weight: 700;
        color: #db110d;
    }
  
</style>
